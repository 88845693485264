import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "widthDisplay", "heightDisplay" ]

  static values = {
    width: Number,
    height: Number,
  }

  connect(){
    this.widthValue = 0
    this.heightValue = 0
    this.evalBrickmold();
  }

  updateSizeConfig(event){
    const $el = event.currentTarget;
    console.log(`selected: ${$el.value}`)
    // Get all the size config divs
    const $configs = Array.prototype.slice.call(document.querySelectorAll('.config'), 0);
    $configs.forEach( el => {
      // Hide all config divs
      el.classList.add('is-hidden');
      el.classList.remove('is-active');
    })
    // Show selected div
    let $choosen = document.getElementById($el.value);
    $choosen.classList.remove('is-hidden');
    $choosen.classList.add('is-active');
  }

  evalBrickmold(){
    let el = document.getElementById("line_item_options_Brickmold");
    let selectedOption = el.options[el.selectedIndex].text;
    let $displaySize = document.getElementById("brickmold-size");
    if (/brickmold/i.test(el.id)) {
      switch(selectedOption) {
        case "Sloped Sill Brickmold":
          this.widthValue = 3
          this.heightValue = 2.375
          this.updateWidthHeight();
          $displaySize.classList.remove('is-hidden')
          break;
        case "Regular Brickmold":
          this.widthValue = 3
          this.heightValue = 3
          this.updateWidthHeight();
          $displaySize.classList.remove('is-hidden')
          break;
        default:
          this.updateWidthHeight();
          $displaySize.classList.add('is-hidden')
      }
    }
  }

  updateWidthHeight(){
    // Get all "config is-active" elements
    const $configOptions = Array.prototype.slice.call(document.querySelectorAll('.config.is-active .select select'), 0);
    let width = 0;
    let height = 0;

    $configOptions.forEach( el => {
      if (/width/i.test(el.id)) {
        width += parseFloat(el.value);
      }
      if (/height/i.test(el.id)) {
        height += parseFloat(el.value);
      }
    })

    this.widthDisplayTarget.innerText = width + this.widthValue;
    this.heightDisplayTarget.innerText = height + this.heightValue;
  }


}
