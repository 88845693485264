import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "modal", "horizontal", "horizontalGridLites", "vertical", "verticalGridLites" ]

  openModal(event) {
    const $target = event.currentTarget
    const gridPattern = $target.options[$target.selectedIndex].text

    switch (true) {
      case /colonial/i.test(gridPattern):
        this.modalTarget.classList.add('is-active')
        break
      case /horizontal/i.test(gridPattern):
      case /vertical/i.test(gridPattern):
        this.modalTarget.classList.add('is-active')
        break
      case /victorian/i.test(gridPattern):
        this.modalTarget.classList.add('is-active')
        break
      default:
        // If user selects "None" from the Grid Pattern select options
        break
    }
  }

  closeModal(){
    this.modalTarget.classList.remove('is-active')
  }

  updateHorizontalGridLitePattern(event){
    let h = event.currentTarget.value
    this.horizontalGridLitesTarget.innerHTML = +h + 1
  }

  updateVerticalGridLitePattern(event){
    let v = event.currentTarget.value
    this.verticalGridLitesTarget.innerHTML = +v + 1
  }
}
