import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["margin", "modal", "discount"]
  static values = { parentDiscount: Number }

  calculate() {
    const marginValue = parseFloat(this.marginTarget.value);
    if (isNaN(marginValue)) {
      alert("Please enter a valid margin value.");
      return;
    }

    const discount = this.calculateDiscount(marginValue);
    this.discountTarget.value = discount.toFixed(2); // Update the discount input field
    this.closeModal();
  }

  calculateDiscount(margin) {
    // Add your discount calculation logic here
    // For demonstration, let's assume a simple calculation
    const current_cost = 100 - this.parentDiscountValue;
    const reciprocal = (100 - margin)/100;
    const end_user_cost = current_cost / reciprocal;
    const correlated_off_list = 100 - end_user_cost;
    return correlated_off_list;
  }

  openModal() {
    this.modalTarget.classList.add('is-active')
  }

  closeModal(){
    this.modalTarget.classList.remove('is-active');
  }
}