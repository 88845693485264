import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["accountPrice", "listPrice", "accountPriceCheckbox", "listPriceCheckbox", "colspan"]

  showAccountPrice(event){
    const $target = event.currentTarget;

    if ($target.checked) {
      this.accountPriceTargets.forEach((target) => {
        target.classList.remove('is-hidden');
      });
    } else {
      this.accountPriceTargets.forEach((target) => {
        target.classList.add('is-hidden');
      });
    }
    this.updateColspan();
  }

  showListPrice(event){
    const $target = event.currentTarget;
    console.log(`Show List Price: ${$target.checked}`)

    if ($target.checked) {
      this.listPriceTargets.forEach((target) => {
        target.classList.remove('is-hidden');
      });
    } else {
      this.listPriceTargets.forEach((target) => {
        target.classList.add('is-hidden');
      });
    }
    this.updateColspan();
  }

  updateColspan() {
    this.colspanTargets.forEach((target) => {
      target.colSpan = this.colspan;
    });
  }

  get colspan() {
    let colspan = 0
    if (this.accountPriceCheckboxTarget.checked == true && this.listPriceCheckboxTarget.checked == true) {
      colspan = 8
    }
    if ((this.accountPriceCheckboxTarget.checked == true && this.listPriceCheckboxTarget.checked == false) ||
    (this.accountPriceCheckboxTarget.checked == false && this.listPriceCheckboxTarget.checked == true)) {
      colspan = 6
    }
    if (this.accountPriceCheckboxTarget.checked == false && this.listPriceCheckboxTarget.checked == false) {
      colspan = 4
    }
    return colspan;
  }

}